@import './app/styles/variable.css';
@import './app/styles/common.css';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

ul{
    list-style-type: none;
}

.bg{
    background: var(--bg-color);
}

.h-100{
    height: 100%!important;
}

.page-title{
    text-align: left;
    font-family: sans-serif;
    color: var(--page-title-color);
    font-weight: 600;
    font-size: 1.5rem;
}

.page-content{
    text-align: left;
    color: var(--page-content-color);
    margin: .5rem 0 1rem 0;
    font-size: 18px;
}

.col{
    display: inline-grid;
    align-content: center;
    padding-top: 1rem;
}

.mat-icon-button.mat-accent {
    color: #727272!important;
}

.mat-icon-button.mat-accent:hover {
    color: white!important;
}

.mat-icon-button i, .mat-icon-button .mat-icon {
    line-height: 2rem!important;
}

.mat-icon {
    height: 2rem!important;
    width: 2rem!important;
}

.material-icons{
    font-size: 1.8rem!important;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #191919; 
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #4d4d4d; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #515151;
}

@media(max-width:576px){

    ::-webkit-scrollbar-track {
        border: 1px solid #343434;
    }

}
